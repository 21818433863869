@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 20px 0 0 20px;
    padding: 13px 20px;
    height: calc(100vh - 20px);
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 270px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
  
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
  .switchdetails-layout-content{
    height: 85lvh;
  }
}

@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
  .switchdetails-main-card{
    display: block;
  }
  .switchdetails-main-card-TimeLineView-parent{
    width: 100%;
  }
  .switchDetail-fan-container{
    height: 40lvh;
    display: flex;
    align-items: center;
  }
  .switchDetail-fan-container-sec{
    /* margin: 50 auto; */
    /* height: 50px; */
  }
}

.button {
  font: inherit;
  background: hsl(185, 53%, 15%);
  border: 0.1em solid hsl(185, 53%, 34%);
  border-radius: 100vw;
  cursor: pointer;
  transition: background-color 250ms;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}
.button:hover,
.button:focus-visible {
  background: hsl(185, 53%, 34%);
}
.button > span {
  position: absolute;
  z-index: -1;
  width: 33.333%;
  height: 100%;

  background: transparent;
  opacity: 0.5;
}
.button > :first-child {
  left: 0;
  top: 0;
}
.button > :last-child {
  right: 0;
  top: 0;
}
.button::before {
  content: "";
  position: absolute;
  z-index: -1;
  background: hsla(283, 3%, 45%, 0.733);
  /* background: hotpink; */
  width: 10%;
  aspect-ratio: 1;
  border-radius: 50%;
  inset: 0;
  margin: auto;
  opacity: 0;

  transition: transform 1000ms 200ms, opacity 200ms;
}
.button:active::before {
  transform: scale(20);
  opacity: 1;

  transition: transform 1000ms, opacity 500ms;
}
.button:has(:first-child:active)::before {
  margin-left: 0;
}
.button:has(:last-child:active)::before {
  margin-right: 0;
}
.button:has(:first-child:active)::before,
.button:has(:last-child:active)::before {
  transition: transform 500ms, opacity 250ms;
}
@keyframes rotation { 
  0% { opacity: 0; }
  100% { opacity: 1; }
 100% {transform: rotate(59deg);}
}
.sensor-container-rotate{
  width: auto; 
  height: auto;
  position: absolute; right: 13px; top: 10px; font-size: 37px;
  color: black;
  animation: rotation 30s infinite linear;
}

@media (max-width:1400px) {
  .selectEcosystem {
    /* height: 100lvh; */
    background: #f0f2f5;
    padding: 50px;
  }
  .ecosystemCard {
    width: 80%;
    margin: 0 auto;
  }
  .ecosystemLogo{
    width: 50px;
  }
  
  .selectEcosystemCardContainer{
    gap: 25px;
  }
  .ecosystemTitle{
    font-size: 10px;
  }
}

@media (max-width:730px) {
  .ecosystemCard {
    width: 200px;
    margin: 0 auto;
  }
  .selectEcosystemHeading{
    font-size: 20px;
  }
  .selectEcosystemCardContainer{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
  }
  .add-data-img{
    width: 200px;
  }
  .welcome-main{
    display: block;
  }
  .image-fluid{
    margin: 0px auto;
    padding: 0px 0px 50px 0px;

  }
  .welcome-logo-parent{
    width: 200px;
    margin: 0 auto;
  }
  .welcome-login-parent{
    padding: 50px 0px 0px 0px;
  }
  .wlc_tank_container {
    flex-direction: column;
  }
  .wlc_timeline_view_container{
    border-left: none;
    border-top: 1px solid grey;
    padding-left: none;
    padding-top: 30px;
  }
  .wcm_l1{
    padding: 0px;
  }
}
@media screen and (max-height: 600px) {
  .welcome-main{
    position: static;
  }
  .welcome-footer{
    position:static;
    width: 100%;
    text-align: center;
  }
}